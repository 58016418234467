/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Notify,LoadingBar,Dialog,Loading,Meta} from 'quasar'



export default { config: {"brand":{"primary":"#1349eb","secondary":"#6226a6","accent":"#9C27B0","dark":"#1d1d1d","positive":"#00a352","negative":"#a30000","info":"#006cd6","warning":"#d66c00"},"notify":{"position":"bottom","closeBtn":false},"loadingBar":{"position":"bottom","color":"primary"}},plugins: {Notify,LoadingBar,Dialog,Loading,Meta} }

